import React, { useState } from 'react'
import {
  Upload,
  Button,
  Table,
  message,
  Card,
  Col,
  Row,
  Modal,
  Typography,
  Divider,
  Select,
  Alert,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import Api from 'api/apiv2'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'

const { Dragger } = Upload
const { Title, Paragraph } = Typography
const { Option } = Select

const BulkImportDonations = () => {
  const { id } = useParams()
  const { organization } = useAuthSelector()
  const { fundraiser } = useFundraiserSelector()

  const [uploadLoading, setUploadLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [columns, setColumns] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hasHeaders, setHasHeaders] = useState(true)
  const [mappings, setMappings] = useState({}) // State to hold column mappings
  const [csvUploadError, setCSVUploadError] = useState('')
  const [showStatisticModal, setShowStatisticModal] = useState(false)
  const [uploadStatisticData, setUploadStatisticData] = useState()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [uploadErrorData, setUploadErrorData] = useState([])

  const requiredColumns = [
    'PhoneNumber',
    'Email',
    'DonationDate',
    'DonatedAmount',
  ]

  // Function to reset the process by clearing all the states
  const resetCSVProcess = () => {
    setDataSource([])
    setColumns([])
    setMappings({})
    setCSVUploadError('')
    setShowStatisticModal(false)
    setUploadStatisticData(null)
    setUploadErrorData([])
    setImportLoading(false)
  }

  const handleFileUpload = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase()
    if (fileExtension !== 'csv') {
      message.error('Only CSV files are allowed!')
      return false
    }

    setUploadLoading(true)
    const reader = new FileReader()

    reader.onload = () => {
      const csvData = reader.result

      Papa.parse(csvData, {
        skipEmptyLines: true,
        complete: (result) => {
          const { data, errors } = result

          if (errors.length > 0) {
            message.error('Error parsing CSV file')
          } else {
            let headers = []
            let modifiedData = data // Create a new variable to hold the sliced data

            if (hasHeaders) {
              ;[headers, ...modifiedData] = data
              modifiedData = data.slice(1) // Use the new variable for modified data
            } else {
              headers = data[0].map((_, index) => `Column ${index + 1}`)
            }

            const tableColumns = headers.map((header) => ({
              title: (
                <div>
                  <Select
                    style={{ width: 150 }}
                    onChange={(value) => handleMappingChange(header, value)}
                    placeholder="Select mapping"
                  >
                    <Option value="FirstName">First Name</Option>
                    <Option value="LastName">Last Name</Option>
                    <Option value="PhoneNumber">Phone Number</Option>
                    <Option value="Email">Email</Option>
                    <Option value="DonationDate">Donation Date</Option>
                    <Option value="DonatedAmount">Donated Amount</Option>
                    <Option value="PaymentMethod">Payment Method</Option>
                    <Option value="OrgDonation">Organization Donation</Option>
                  </Select>
                </div>
              ),
              dataIndex: header,
              key: header,
            }))

            const tableData = modifiedData.map((row, index) => {
              const rowData = {}
              headers.forEach((header, i) => {
                rowData[header] = row[i]
              })
              return { key: index, ...rowData }
            })

            setColumns(tableColumns)
            setDataSource(tableData)
            message.success('CSV file parsed successfully')
            setIsModalVisible(false)
            setUploadLoading(false)
          }
        },
        error: (error) => {
          message.error('Failed to parse the CSV file', error)
          setUploadLoading(false)
        },
      })
    }

    reader.readAsText(file)
    return false
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const uploadProps = {
    customRequest: ({ file }) => handleFileUpload(file),
    showUploadList: false,
    maxCount: 1,
    accept: '.csv',
  }

  const handleHeaderToggle = (e) => {
    setHasHeaders(e.target.checked)
  }

  const handleMappingChange = (header, value) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [header]: value,
    }))
  }

  // Validate that at least 4 required columns are mapped
  const validateMappings = () => {
    const missingMappings = requiredColumns.filter(
      (col) => !Object.values(mappings).includes(col),
    )

    if (missingMappings.length > 0) {
      setCSVUploadError(
        `The following required columns are not mapped: ${missingMappings.join(
          ', ',
        )}`,
      )
      return false
    }

    setCSVUploadError('') // Clear any previous error
    return true
  }

  const handleBulkTransactionsUpload = async () => {
    if (organization && fundraiser) {
      const organizationData = {
        OrganizationId: organization.id,
        OrganizationName: organization.Name,
        OrganizationEmail: organization.Email,
      }

      const fundraiserData = {
        FundraiserId: fundraiser.iD,
        FundraiserTitle: fundraiser.title,
      }

      if (!validateMappings()) {
        return
      }

      // Create an array of objects based on the mappings
      const donationsData = dataSource.map((row) => {
        const mappedRow = {}
        Object.keys(mappings).forEach((header) => {
          const mappedKey = mappings[header]
          if (mappedKey) {
            mappedRow[mappedKey] = row[header] // Map the value to the new key
          }
        })
        return mappedRow
      })

      const payload = {
        donationsData,
        organizationData,
        fundraiserData,
      }

      try {
        setImportLoading(true)
        const result = await Api.post(
          `admin/fundraisers/${id}/bulk-donation`,
          payload,
        )

        const response = result.data

        message.success(response.message)

        setUploadStatisticData({
          numberOfDonationsAdded: response.donations.numberOfDonationsAdded,
          numberOfNewConstituent: response.donations.numberOfNewConstituent,
          numberOfSkippedConstituent:
            response.donations.numberOfSkippedConstituent,
          errorInRecords: response.donations.errorInRecords,
          errorData: response.donations.errorData,
        })
        setUploadErrorData(response.donations.errorData)
        setShowStatisticModal(true)
      } catch (error) {
        message.error(
          error?.response?.data?.error?.message ||
            'Bulk Import Donation File cannot be processed due to an error',
        )
        setImportLoading(false)
      }
    } else {
      console.error('Could not get Organization OR fundraiser data')
    }
  }

  const downloadBulkImportErrorFile = () => {
    setDownloadLoading(true)

    const formattedData = uploadErrorData.map((item) => ({
      FirstName: item.FirstName || '',
      LastName: item.LastName || '',
      PhoneNumber: item.PhoneNumber || '',
      Email: item.Email || '',
      DonationDate: item.DonationDate || '',
      DonatedAmount: item.DonatedAmount || '',
      PaymentMethod: item.PaymentMethod || '',
      OrgDonation: item.OrgDonation || '',
      Error: item.Errors,
    }))

    // Convert the data to CSV using PapaParse
    const csv = Papa.unparse(formattedData)

    // Create a Blob with CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

    // Create a link to trigger download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'BulkTransactionUpload_error_data.csv' // Name of the CSV file
    link.click()

    // Reset loading state after download
    setDownloadLoading(false)
  }

  return (
    <Card className="t-w-full">
      <Row justify="end" gutter={[24, 0]}>
        <Col>
          <div className="t-w-full t-mb-4">
            <Button
              className="t-w-full t-mt-2"
              type="primary"
              onClick={showModal}
              loading={uploadLoading}
            >
              Upload File
            </Button>
          </div>
        </Col>

        <Col>
          {dataSource.length > 0 && (
            <Row justify="end" gutter={[24, 0]}>
              <Col>
                <div className="t-w-full t-mb-4">
                  <Button
                    className="t-w-full t-mt-2"
                    type="primary"
                    onClick={handleBulkTransactionsUpload}
                    loading={importLoading}
                  >
                    Import
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {csvUploadError && (
        <Alert
          message="Error"
          description={csvUploadError}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
          closable
          afterClose={() => setCSVUploadError('')}
        />
      )}

      <Table
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{ pageSize: 10 }}
        scroll={dataSource.length > 0 ? { x: true } : undefined}
      />

      <Modal
        title="Bulk Transactions Upload Instructions"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        destroyOnClose
      >
        <Typography>
          <Paragraph>
            Following are the instructions for Bulk Transactions Upload:
          </Paragraph>
          <ul>
            <li>
              Please upload any well-formed CSV file. Column names are not
              case-sensitive.
            </li>
            <li>
              If the first row contains header information, select
              &quot;Yes&quot; on the following prompt.
            </li>
            <li>
              After uploading the CSV, you will be able to map the columns to
              the corresponding fields in Fundraiser.
            </li>
            <li>
              <strong>(Optional)</strong> To import donations to the
              organization, add a new column to your CSV named
              <strong> &quot;[Organization Donation]&quot;</strong>.
            </li>
            <li>
              Leading and trailing spaces in data will be automatically trimmed.
            </li>
            <li>
              Please double-check that all numbers are in the correct format.
            </li>
            <li>
              If we encounter any issues importing a row, we will provide an
              option to download those specific rows for correction and
              subsequent re-upload.
            </li>
            <li>
              Valid acceptable payment method will be accepted, Valid acceptable
              payment methods are: Credit Card, Cash, Check, Debit Card, ACH,
              Zelle, Venmo, PayPal, CashApp, Wire Transfer, Other
            </li>
          </ul>
          <Divider />
          <Title level={5}>Upload Your CSV File Here</Title>
          <div className="t-flex t-items-start t-justify-start">
            <label>
              Does your file contain headers?
              <input
                type="checkbox"
                checked={hasHeaders}
                onChange={handleHeaderToggle}
                className="t-ml-2"
              />
            </label>
          </div>
          <Divider />
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Only <strong>CSV</strong> files are accepted.
            </p>
          </Dragger>
        </Typography>
      </Modal>

      <Modal
        centered
        title="Bulk Import Donation Upload Statistics"
        open={showStatisticModal}
        onCancel={() => {
          setShowStatisticModal(false)
          setImportLoading(false)
          resetCSVProcess()
        }}
        footer={null}
        destroyOnClose
      >
        {uploadStatisticData && (
          <div>
            {/* Displaying statistics as Alerts */}
            {[
              {
                name: 'Donations added',
                value: 'numberOfDonationsAdded',
                type: 'success', // Success alert
              },
              {
                name: 'Constituents added',
                value: 'numberOfNewConstituent',
                type: 'success', // Success alert
              },
              {
                name: 'Constituents skipped (because they already exist)',
                value: 'numberOfSkippedConstituent',
                type: 'warning', // Warning alert
              },
              {
                name: 'Records not imported due to errors',
                value: 'errorInRecords',
                type: 'error', // Error alert
              },
            ].map((field) => (
              <Alert
                key={field.value}
                message={field.name}
                description={`Count: ${uploadStatisticData[field.value]}`}
                type={field.type}
                className="t-mb-4"
                showIcon
              />
            ))}
            {uploadStatisticData.errorInRecords > 0 && (
              <div className="t-mb-8 t-flex t-items-center t-justify-end">
                <Button
                  className="t-mt-2"
                  type="danger"
                  loading={downloadLoading}
                  onClick={downloadBulkImportErrorFile}
                >
                  Download Error Records File
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </Card>
  )
}

export { BulkImportDonations }
