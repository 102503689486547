import {
  Button,
  Col,
  Form,
  Input,
  // InputNumber,
  Row,
  Select,
  Skeleton,
  Upload,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { MdDelete, MdOutlineFileUpload } from 'react-icons/md'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
// import { MaskedInput } from 'antd-mask-input'
import { TaxIdFormating } from 'utils'
import { updateOrganizatoinV2 } from 'store/organizationSlice/organizationActions'
import { organizationUpdateAboutValidation } from 'validations/updateAbout'
import { validateOrgUrl } from 'store/authSlice/authActions'
import { awsconfig, config } from 'configs'
import { AiFillCheckCircle } from 'react-icons/ai'
import { CustomizeImageUploader } from './CustomizeImageUploader'
import { AutoCompleteAddress } from './AutoCompleteAddress'

const { TextArea } = Input

const organizationTypes = [
  'Art Gallery',
  'Auction House',
  'Educational Institution',
  'Employee Benefit Association or Funds',
  'Fraternal Societies',
  'Government Entity',
  'Healthcare Institutions',
  'Labor Organization',
  'Law Enforcement',
  'Political Organization',
  'Religious Organization',
  'Social Clubs',
  'Social Welfare',
  'Sporting Venue',
  'Trade Association',
  'Veterans Organization',
  'Other',
]

const IRSTypes = [
  '501(c)(3)',
  '501(c)(4)',
  '501(c)(6)',
  '501(c)(7)',
  '501(c)(8)',
  '501(c)(9)',
  '501(c)(10)',
  '501(c)(17)',
  '501(c)(19)',
  '501(c)(23)',
  'S-Corp',
  'C-Corp',
  'LLC',
  'IRC Section 527',
]

const initialValues = {
  Name: '',
  OrganizationType: '',
  StreetAddress: '',
  UrlPrefix: '',
  InternationallyFunded: '',
  TaxId: '',
  PhoneNumber: '',
  OrgDomain: '',
  Website: '',
  Description: '',
  IdentityType: '',
  IdentityFile: [],
  W9Form: null,
  FundedOutside: false,
  BackgroundImage: null,
  Logo: null,
}

const About = () => {
  const [loading, setLoading] = useState(true)
  const [aboutData, setAboutData] = useState({})
  const [fileList, setFileList] = useState([]) // State for file list
  const [buttonLoading, setButtonLoading] = useState(false)
  const [validUrl, setValidUrl] = useState(false)
  const { organization, user } = useAuthSelector()

  const [form] = Form.useForm()

  const history = useHistory()

  const validateUrl = async (url) => {
    setButtonLoading(true)
    if (url) {
      try {
        const valid = await validateOrgUrl(url, user?.id)
        if (valid) {
          message.destroy()
          message.success('Validated Organization Domain successfully')
        } else {
          message.destroy()
          message.error('Organization Domain is already taken')
        }
        setButtonLoading(false)
        return valid
      } catch (error) {
        console.log('error', error)
        message.destroy()
        message.error(error?.response?.data?.message || 'Validation error')
        setButtonLoading(false)
        return false
      }
    }
    return false
  }

  const onFinish = async (values) => {
    const isUrlValid = await validateUrl(values.OrgDomain)
    if (!isUrlValid) {
      return
    }
    let data = {
      OrganizationType: values.OrganizationType,
      Email: values.Email,
      OrgEIN: values.OrgEIN,
      OrgIRSType: values.OrgIRSType,
      Address1: values.StreetAddress,
      PhoneNumber: values.PhoneNumber,
      OrgDomain: values.OrgDomain,
      Website: values.Website,
      Description: values.Description,
      IdentityFile: values.IdentityFile,
      W9Form: values.W9Form,
      FundedOutside: values.InternationallyFunded === 'Yes',
      BackgroundImage: values.BackgroundImage,
      Logo: values.Logo,
    }

    if (values.W9Form) {
      data.W9Form = values.W9Form
    }
    if (values.IdentityFile) {
      data.IdentityFile = [values.IdentityFile]
    }

    try {
      setButtonLoading(true)
      data = { organizationId: organization?.id, ...data }
      await updateOrganizatoinV2(data)
      message.success(`Organization Detail Updated Successfully`)
      history.push('/settings/manage-organization/organization-detail')
      setButtonLoading(false)
    } catch (error) {
      message.error(error.response?.data?.message || 'Something Went Wrong')
      setButtonLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Fail', errorInfo)
  }

  const handleBack = () => {
    history.push(config.defaultRedirect.subscriber)
  }

  const handleDownload = () => {
    const pdfUrl = 'https://www.irs.gov/pub/irs-pdf/fw9.pdf'
    window.open(pdfUrl, '_blank')
  }

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    form.setFieldsValue({
      W9Form: newFileList[0] ? newFileList[0].originFileObj : null,
    })
  }

  const handleDelete = () => {
    setFileList([])
    form.setFieldsValue({ W9Form: null })
  }

  const getAboutData = () => {
    setLoading(true)
    makeRequest('get', '/webapp/organization?type=about', {
      organizationId: organization?.id,
    })
      .then((res) => {
        setLoading(false)
        setAboutData(res?.item)
        form.setFieldsValue({
          Name: res.item.Name,
          OrganizationType: res.item.OrganizationType,
          Email: res.item.Email,
          OrgEIN: res.item.TaxId,
          OrgIRSType: res.item.OrgIRSType,
          StreetAddress: res.item.Address1,
          PhoneNumber: res.item.PhoneNumber,
          OrgDomain: res.item.OrgDomain,
          Website: res.item.Website,
          InternationallyFunded: res.item.FundedOutside ? 'Yes' : 'No',
          Description: res.item.Description,
          IdentityFile: res.item.IdentityFile[0] || [],
          W9Form: res.item.W9Form || null,
          BackgroundImage: res.item.BackgroundImage,
          Logo: res.item.Logo,
          AddressData: {
            StreetAddress: res.item.Address1,
          },
        })
        if (res.item.W9Form) {
          setFileList([{ name: res.item.W9Form.fileName }])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong!')
      })
  }

  useEffect(() => {
    getAboutData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id])

  // const handleInputChange = (value) => {
  //   form.setFieldsValue({ PhoneNumber: value })
  // }

  const handleUrlChange = (value) => {
    const val = value.replace(/[^0-9a-zA-Z]/g, '')
    const lowerCaseVal = val?.toLowerCase()
    form.setFieldsValue({ OrgDomain: lowerCaseVal })
    setValidUrl(false)
  }

  return (
    <>
      <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
        <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 24,
            }}
            className="t-w-[90%] md:t-w-[100%] t-m-auto ant-roboto-font"
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
              <h1 className="t-text-base t-font-semibold t-text-black roboto-font">
                {aboutData?.Name}
              </h1>
              <h1 className="t-text-sm t-text-black t-ml-20 sm:t-ml-32 roboto-font ">
                <strong>EIN: </strong>
                {TaxIdFormating(aboutData?.TaxId)}
              </h1>
            </div>

            <Form.Item
              label="Organization Type* "
              name="OrganizationType"
              rules={[organizationUpdateAboutValidation]}
            >
              <Select>
                {organizationTypes?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Organization Email"
              name="Email"
              rules={[organizationUpdateAboutValidation]}
            >
              <Input
                placeholder="Organization Email"
                defaultValue={aboutData.Email}
              />
            </Form.Item>
            <Form.Item
              label="Organization EIN"
              name="OrgEIN"
              rules={[organizationUpdateAboutValidation]}
            >
              <Input
                placeholder="Organization EIN"
                value={TaxIdFormating(aboutData.TaxId)}
                defaultValue={TaxIdFormating(aboutData.TaxId)}
              />
            </Form.Item>
            <Form.Item
              label="IRS Tax Type"
              name="OrgIRSType"
              colon={false}
              rules={[organizationUpdateAboutValidation]}
            >
              <Select placeholder="IRS Tax Type" style={{ width: '100%' }}>
                {IRSTypes.map((item, index) => (
                  <Select.Option value={item} key={index}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <AutoCompleteAddress
              form={form}
              validation
              AddressData={{
                StreetAddress: aboutData.Address1,
              }}
            />

            <Form.Item
              label="Phone Number*"
              name="PhoneNumber"
              rules={[organizationUpdateAboutValidation]}
            >
              {/* <MaskedInput
                inputMode="numeric"
                mask={MaskPattern}
                onChange={(e) => {
                  handleInputChange(e.target.value)
                }}
              /> */}
              <Input
                inputMode="numeric"
                placeholder="3333xxxxxxx"
                className="t-w-full t-rounded-md"
              />
            </Form.Item>
            <Form.Item
              label="Custom URL Prefix*"
              name="OrgDomain"
              colon={false}
              rules={[organizationUpdateAboutValidation]}
            >
              <Col
                span={24}
                className="t-flex t-gap-1 t-text-xs t-justify-between t-items-center "
              >
                <Input
                  suffix={`.${awsconfig.API.endpoints[0].api_url?.replace(
                    /^https?:\/\//,
                    '',
                  )}`}
                  value={aboutData.OrgDomain}
                  onChange={(e) => handleUrlChange(e.target.value)}
                />

                <div></div>
                {validUrl ? (
                  <div className="t-flex t-space-x-2 t-items-center">
                    <AiFillCheckCircle
                      fontSize="1.5rem"
                      className="t-text-green-600"
                    />
                    <h1 className="t-font-bold t-text-sm">
                      Organization Domain Validated Successfully
                    </h1>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => validateUrl(form.getFieldValue('OrgDomain'))}
                    id="validate-address-button"
                  >
                    Validate
                  </Button>
                )}
              </Col>
            </Form.Item>
            <Form.Item
              label="Website"
              name="Website"
              rules={[organizationUpdateAboutValidation]}
            >
              <Input placeholder="www.extensia.com" />
            </Form.Item>
            <Form.Item
              label="Internationally Funded*"
              name="InternationallyFunded"
              rules={[organizationUpdateAboutValidation]}
            >
              <Select>
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Description* "
              name="Description"
              rules={[organizationUpdateAboutValidation]}
            >
              <TextArea rows={2} placeholder="Enter the description" />
            </Form.Item>
            <h1 className="t-text-black t-text-base t-font-semibold t-mt-8 t-border-b t-border-b-gray-500 roboto-font">
              Update Files:
            </h1>
            <h6 className="t-text-sm t-font-normal roboto-font">
              Max. File Size: 5MB
            </h6>
            <Form.Item
              label="Logo* "
              name="Logo"
              rules={[organizationUpdateAboutValidation]}
              className="t-mt-4"
            >
              <CustomizeImageUploader
                defaultFile={
                  form.getFieldValue('Logo') && form.getFieldValue('Logo')
                }
                onFileChange={(file) =>
                  form.setFieldsValue({
                    Logo: file?.originFileObj,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              name="BackgroundImage"
              rules={[organizationUpdateAboutValidation]}
            >
              <div className="t-flex t-flex-col">
                <div className="t-flex t-flex-row t-justify-between t-mb-2">
                  <h1 className="t-text-[14px] t-font-semibold roboto-font">
                    Background Image*
                  </h1>
                  <h1 className="t-text-[12px]">Size ratio 16:9</h1>
                </div>
                <CustomizeImageUploader
                  ratio={16 / 9}
                  defaultFile={
                    form.getFieldValue('BackgroundImage') &&
                    form.getFieldValue('BackgroundImage')
                  }
                  onFileChange={(file) =>
                    form.setFieldsValue({
                      BackgroundImage: file?.originFileObj,
                    })
                  }
                />
              </div>
            </Form.Item>
            <Form.Item
              name="W9Form"
              rules={[organizationUpdateAboutValidation]}
            >
              <div className="t-flex t-flex-col">
                <div className="t-flex t-flex-row t-justify-between t-mb-2">
                  <h1 className="t-text-[14px] t-font-semibold roboto-font">
                    Form W9*
                  </h1>
                  <h1
                    className="t-text-[12px] t-cursor-pointer hover:t-underline"
                    onClick={handleDownload}
                  >
                    Download template
                  </h1>
                </div>
                <Upload
                  maxCount={1}
                  customRequest={(e) => form.setFieldsValue({ W9Form: e.file })}
                  showUploadList={false}
                  onChange={handleFileChange} // Track file changes
                >
                  {fileList?.length === 0 ? (
                    <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%] ">
                      <MdOutlineFileUpload className="t-text-2xl t-mr-2" />
                      Upload
                    </Button>
                  ) : (
                    <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%]">
                      <MdDelete
                        onClick={handleDelete}
                        className="t-text-2xl t-mr-2"
                      />
                      {fileList[0]?.name}
                    </Button>
                  )}
                </Upload>
              </div>
            </Form.Item>
            <Form.Item
              name="IdentityFile"
              rules={[organizationUpdateAboutValidation]}
            >
              <div className="t-flex t-flex-col">
                <div className="t-flex t-flex-row t-justify-between t-mb-2">
                  <h1 className="t-text-[14px] t-font-semibold roboto-font">
                    Photo ID*
                  </h1>
                  <h1 className="t-text-[12px]">Passport / Drivers License</h1>
                </div>
                <CustomizeImageUploader
                  ratio={16 / 9}
                  defaultFile={
                    form.getFieldValue('IdentityFile') &&
                    form.getFieldValue('IdentityFile')
                  }
                  onFileChange={(file) =>
                    form.setFieldsValue({
                      IdentityFile: file?.originFileObj,
                    })
                  }
                />
              </div>
            </Form.Item>
            <Row className="t-flex t-justify-center lg:t-justify-end t-item-center t-mt-8">
              <Col span={8} className="t-mx-3">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="t-w-[85%] t-text-[14px]"
                    onClick={handleBack}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={buttonLoading}
                    className="t-w-[85%] t-text-[14px] t-bg-[#2e97de]"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Skeleton>
    </>
  )
}

export { About }
